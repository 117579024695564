import React from 'react';
import { motion } from 'framer-motion';
import './ShowDates.css';

const ShowDates: React.FC = () => {
	const shows = [
		{
			date: '8/3',
			venue: 'Iron Tails Saloon',
			location: 'Acton, ME',
			time: '7-11pm',
		},
		{
			date: '8/9',
			venue: 'Bentley’s Saloon',
			location: 'Arundel, ME',
			time: '7-11pm',
		},
		{
			date: '8/10',
			venue: 'The Garage at The Governors Inn',
			location: 'Rochester, NH',
			time: '7-10pm (outdoor show!) $5 cover',
		},
		{
			date: '9/8',
			venue: 'Hampton Beach Seafood Festival',
			location: 'Hampton, NH',
			time: '12pm-2:30pm',
		},
		{
			date: '9/14',
			venue: 'The Oaks Golf Links',
			location: 'Somersworth, NH',
			time: 'time TBA',
		},
		{
			date: '10/5',
			venue: 'Powderkeg Beer and Chili Fest',
			location: 'Exeter, NH',
			time: 'time TBA',
		},
		{
			date: '10/11',
			venue: "Train's Tavern",
			location: 'Lebanon, ME',
			time: '7-11pm',
		},
		{
			date: '10/26',
			venue: 'Champions Sports Bar',
			location: 'Biddeford, ME',
			time: '8:30pm-12:30am',
		},
		{
			date: '11/9',
			venue: 'East Side Club',
			location: 'Manchester, NH (members & guests only)',
			time: '7-11pm',
		},
		{
			date: '11/23',
			venue: 'Train’s Tavern',
			location: 'Lebanon, ME',
			time: '7-11pm',
		},
	];

	// Helper function to parse show date into a Date object
	const parseDate = (dateStr: string) => {
		const [month, day] = dateStr.split('/').map(Number);
		const currentYear = new Date().getFullYear();
		return new Date(currentYear, month - 1, day);
	};

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	// Filter out past shows
	const upcomingShows = shows.filter((show) => {
		console.log('show.date', show.date);
		return parseDate(show.date) >= yesterday;
	});

	return (
		<div className="show-dates-container">
			<h1 className="show-dates-title">Upcoming Shows</h1>
			<ul className="show-dates-list">
				{upcomingShows.map((show, index) => (
					<motion.li
						key={index}
						className="show-date-item"
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ delay: index * 0.2, duration: 0.6 }}>
						<div className="date">{show.date}</div>
						<div className="details">
							<div className="venue">{show.venue}</div>
							<div className="location">{show.location}</div>
							<div className="time">{show.time}</div>
						</div>
					</motion.li>
				))}
			</ul>
		</div>
	);
};

export default ShowDates;
